@use 'sass:list';

$breakpoints: xl-up, xl-down, lg-up, lg-down, md-up, md-down, sm-up, sm-down,
  s-up, s-down, tablet;

@mixin devices($breakpoint) {
  //the name of the mixin is devices
  @if list.index($breakpoints, $breakpoint) {
    @if $breakpoint == xl-up {
      @media only screen and (min-width: 1536px) {
        @content;
      }
    }

    @if $breakpoint == xl-down {
      @media only screen and (max-width: 1535.95px) {
        @content;
      }
    }

    @if $breakpoint == lg-up {
      @media only screen and (min-width: 1200px) {
        @content;
      }
    }

    @if $breakpoint == lg-down {
      @media only screen and (max-width: 1199.95px) {
        @content;
      }
    }

    @if $breakpoint == md-up {
      @media only screen and (min-width: 900px) {
        @content;
      }
    }

    @if $breakpoint == md-down {
      @media only screen and (max-width: 899.95px) {
        @content;
      }
    }

    @if $breakpoint == sm-up {
      @media only screen and (min-width: 600px) {
        @content;
      }
    }

    @if $breakpoint == sm-down {
      @media only screen and (max-width: 599.95px) {
        @content;
      }
    }

    @if $breakpoint == s-up {
      @media only screen and (min-width: 375px) {
        @content;
      }
    }

    @if $breakpoint == s-down {
      @media only screen and (max-width: 374.95px) {
        @content;
      }
    }

    @if $breakpoint == tablet {
      @media (min-width: 600px) and (pointer: coarse) and (orientation: portrait),
        (min-width: 900px) and (max-width: 1200px) and (pointer: coarse) {
        @content;
      }
    }
  } @else {
    @error "$breakpoint argument must exist in #{$breakpoints}; was '#{$breakpoint}'";
  }
}
