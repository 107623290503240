@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.article {
  border-radius: spacing(0.5);
  padding: spacing(2, 1);
  background-color: var(--common-white);

  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  &.with-top-margin {
    margin-top: spacing(3);
  }

  @include devices(md-up) {
    padding: spacing(2);
  }
}
